<script>
import useVuelidate from '@vuelidate/core'
import {required, requiredIf, email} from '@vuelidate/validators'
import {FilterMatchMode} from 'primevue/api';

/* COMPOSANTS */
import AkFormList from "@components/layout/AkFormList";
import AkInputText from "@components/input/AkInputText";
import AkDropdown from "@components/input/AkDropdown";
import AkDialog from "@components/general/AkDialog";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import utilsMixin from "@mixins/utilsMixin";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import agerService from "@services/agerService";
import uerService from "@services/uerService";
import ceiService from "@services/ceiService";
import userService from "@services/userService";
import userGroupService from "@services/userGroupService";
import rolePermissionService from "@services/rolePermissionService";
import mobileMixin from "@mixins/mobileMixin";

export default {
  components: {AkFormList, AkInputText, AkDropdown, AkDialog},
  mixins: [randomRef, utilsMixin, roleMixin, mobileMixin],
  metaInfo() {
    return {
      title: "user.list"
    }
  },
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      loading: false,
      list: [],
      current: {},
      
      agerList: [],
      uerList: [],
      ceiList: [],
      ugList: [],
      
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
      }
    }
  },
  validations() {
    return {
      current: {
        lastName: {required},
        firstName: {required},
        password: {required: requiredIf(!this.current.id && this.isPasswordRequired)},
        email: {required:requiredIf(this.isLoginRequired), email},
        userGroupId: {required},
        agerId: {required: requiredIf(this.showAger(this.getUserGroupById(this.current.userGroupId)))},
        uerId: {required: requiredIf(this.showUer(this.getUserGroupById(this.current.userGroupId)))},
        ceiId: {required: requiredIf(this.showCei(this.getUserGroupById(this.current.userGroupId)))}
      }
    }
  },
  mounted() {
    this.getRef().showTotalLoader();
  
    let p0 = rolePermissionService.authorizeAccessByPermission(this.permissionConst.admin.view);

    let p1 = agerService.findAllCurrentForAdmin();
    p1.then(data => this.agerList = data);
  
    let p2 = uerService.findAllCurrent();
    p2.then(data => this.uerList = data);

    let p3 = ceiService.findAllCurrent();
    p3.then(data => this.ceiList = data);

    let p4 = userService.findAll();
    p4.then(data => this.list = data);
  
    let p5 = userGroupService.findAll();
    p5.then(data => this.ugList = data);

    Promise.all([p0, p1, p2, p3, p4, p5]).then(()=>{
      this.loading = false;
      this.getRef().hideLoader();
    });
  },
  methods: {
    exportList() {
     userService.exportList();
    },
    create() {
      this.getRef().resetMessages();
      this.v$.$touch();
      if (this.v$.$error) return;
      
      this.getRef().showTotalLoader();
      if (!this.showCei(this.getUserGroupById(this.current.userGroupId))) this.current.ceiId = null;
      if (!this.showUer(this.getUserGroupById(this.current.userGroupId))) this.current.uerId = null;
      if (!this.showAger(this.getUserGroupById(this.current.userGroupId))) this.current.agerId = null;
      
      userService.create(this.current).then(data => {
        this.list.push(data);
        this.current = {};
        
        this.getRef().success(this.$t("user.added"));
        this.$refs.dialogCreate.hide();
      }).catch(e => {
        if (e.response) {
          if (e.response.status === 409) this.getRef().error(this.$t('login_already_exists'));
        } else {
          this.getRef().error(this.$t('unknown_error'));
        }
      }).finally(() => {
        this.getRef().hideLoader();
      });
    },
    update() {
      if(this.current.password===null) this.current.password = '';
      
      this.getRef().resetMessages();
      this.v$.$touch();
      if (this.v$.$error) return;
      
      this.getRef().showTotalLoader();
      if (!this.showCei(this.getUserGroupById(this.current.userGroupId))) this.current.ceiId = null;
      if (!this.showUer(this.getUserGroupById(this.current.userGroupId))) this.current.uerId = null;
      if (!this.showAger(this.getUserGroupById(this.current.userGroupId))) this.current.agerId = null;
      
      userService.update(this.current).then(data => {
        this.replaceInListById(this.list, data);
        this.current = {};
        
        this.getRef().success(this.$t("user.updated"));
        this.$refs.dialogUpdate.hide();
      }).catch(e => {
        if (e.response) {
          if (e.response.status === 409) this.getRef().error(this.$t('login_already_exists'));
        } else {
          this.getRef().error(this.$t('unknown_error'));
        }
      }).finally(() => {
        this.getRef().hideLoader();
      });
    },
    delete() {
      this.getRef().resetMessages();
      userService.delete(this.current).then(data => {
        this.removeInListById(this.list, data.id);
        this.getRef().success(this.$t("user.deleted"))
      }).catch(e => {
        this.getRef().error(e.response.data.error);
      });
    },
    getUserGroupById(id) {
      return this.ugList.filter(ug => ug.id === id)[0];
    },
    openCreateDialog() {
      this.current = {};
      this.$refs.dialogCreate.show();
    },
    openUpdateDialog(data) {
      this.current = {...data};
      this.$refs.dialogUpdate.show();
    },
    openDeleteDialog(data) {
      this.current = data;
      this.$refs.dialogDelete.show();
    },
  },
  computed: {
    uerListForCurrent() {
      return this.uerList.filter(uer => uer.agerId === this.current.agerId);
    },
    ceiListForCurrent() {
      return this.ceiList.filter(cei => cei.uerId === this.current.uerId);
    },
    isPasswordRequired(){
      return !this.isAgent;
    },
    isLoginRequired(){
      return !this.isAgent;
    },
    currentUG(){
      if(null == this.current?.userGroupId) return null;
      return this.ugList.find(ug => this.current?.userGroupId === ug.id);
    },
    isAgent(){
      return "AGENT" === this.currentUG?.techName
    }
  },
  watch: {
    uerListForCurrent() {
      if(!this.uerListForCurrent.some(uer => uer.id===this.current.uerId))
        this.current.uerId = undefined;
    },
    ceiListForCurrent() {
      if(!this.ceiListForCurrent.some(cei => cei.id===this.current.ceiId))
        this.current.ceiId = undefined;
    }
  }
}
</script>

<template v-if="!roleLoading">
  <AkFormList :ref="ref" :title="$t('user.list')">
    <template v-slot:action>
      <div class="action-bar" v-if="!isMobile">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText v-model="filters['global'].value" :placeholder="$t('search_here')" />
        </span>
        <button v-if="this.canEditAdmin()"
          class="btn btn-inverse-primary float-right"
          @click="openCreateDialog()">
          <i class="fe fe-plus pr-1"/>
          {{ $t('add') }}
        </button>
        <button
           class="btn btn-inverse-primary"
           @click="exportList">
           <i class="fe fe-download pr-1"/>
           {{ $t('user.exportList') }}
        </button>
      </div>
    </template>
    <template v-slot:search>
      <div class="row" v-if="isMobile">
        <div class="col-lg-12">
          <div class="card card-statistics mb-30">
            <div class="card-body action-card flex-start">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" :placeholder="$t('search_here')" />
              </span>
              <button v-if="this.canEditAdmin()"
                      class="btn btn-inverse-primary float-right"
                      @click="openCreateDialog()">
                <i class="fe fe-plus pr-1"/>
                {{ $t('add') }}
              </button>
              <button
                  class="btn btn-inverse-primary"
                  @click="exportList">
                <i class="fe fe-download pr-1"/>
                {{ $t('user.exportList') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:list>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable
                  :always-show-paginator="false" :loading="loading" :paginator="true" :rows="10"
                  :rowsPerPageOptions="[10,20,50]" :value="list" class="table" v-model:filters="filters"
                  :globalFilterFields="['lastName','firstName','email','tel','agerLabel','uerLabel','ceiLabel']"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  removableSort stripedRows sortMode="multiple" responsiveLayout="scroll">
                  <template #empty>
                    {{ $t("list_empty") }}
                  </template>
                  <Column field="lastName" :header="$t('user.last_name')" :sortable="true">
                    <template #body="slotProps">
                          {{ slotProps.data.lastName }}
                    </template>
                  </Column>
                  <Column field="firstName" :header="$t('user.first_name')" :sortable="true">
                    <template #body="slotProps">
                          {{ slotProps.data.firstName }}
                    </template>
                  </Column>
                  <Column field="email" :header="$t('user.email')" :sortable="true">
                    <template #body="slotProps">
                          {{ slotProps.data.email }}
                    </template>
                  </Column>
                  <Column field="tel" :header="$t('user.tel')" :sortable="true">
                    <template #body="slotProps">
                          {{ slotProps.data.tel }}
                    </template>
                  </Column>
                  <Column field="userGroupLabel" :header="$t('group')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.userGroupLabel }}
                    </template>
                  </Column>
                  <Column field="agerLabel" :header="$t('ager_label')" :sortable="true">
                    <template #body="slotProps">
                          {{ slotProps.data.agerLabel }}
                    </template>
                  </Column>
                  <Column field="uerLabel" :header="$t('uer_label')" :sortable="true">
                    <template #body="slotProps">
                          {{ slotProps.data.uerLabel }}
                    </template>
                  </Column>
                  <Column field="ceiLabel" :header="$t('cei_label')" :sortable="true">
                    <template #body="slotProps">
                          {{ slotProps.data.ceiLabel }}
                    </template>
                  </Column>
                  <Column 
                    bodyStyle="text-align: right; overflow: visible"
                    headerStyle="width: 160px; text-align: center">
                    <template #body="slotProps">
                      <span 
                         v-if=this.canEditAdmin() 
                         class="btn btn-xs btn-inverse-primary pointer"
                         @click="openUpdateDialog(slotProps.data)">
                        <i class="fe fe-edit"/></span>
                      <span 
                         v-if=this.canDeleteAdmin() 
                         class="btn btn-xs btn-inverse-danger ml-1"
                         @click="openDeleteDialog(slotProps.data)">
                        <i class="fe fe-trash"/></span>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:extra>
      <AkDialog ref="dialogCreate"
                :title="$t('user.create_dialog')"
                :validate-label="$t('add')"
                width="700px"
                @validate="this.create()">
        <div>
          
           <div class="form-row">
             <AkInputText :label="$t('user.last_name')"
                          v-model="current.lastName"
                          :validator="v$.current.lastName"
                          class-name="col-6"/>
             <AkInputText :label="$t('user.first_name')"
                          v-model="current.firstName"
                          :validator="v$.current.firstName"
                          class-name="col-6"/>
              <AkInputText :label="$t('user.tel')"
                          v-model="current.tel"
                          :validator="v$.current.tel"
                          class-name="col-6"/>
           </div>
           <div class="form-row">
           <AkDropdown :label="$t('user.ug_label')"
                         v-model="current.userGroupId"
                         :validator="v$.current.userGroupId"
                         :options=this.ugList
                         option-value="id"
                         option-label="name"
                         class-name="col-6"/>
                    </div>
       
           <div class="form-row">
            
             <AkDropdown :label="$t('ager_label')"
                         v-if="current.userGroupId && showAger(this.getUserGroupById(current.userGroupId))"
                         v-model="current.agerId"
                         :validator="v$.current.agerId"
                         :options=this.agerList
                         option-value="id"
                         option-label="label"
                         class-name="col-6"/>
          
             <AkDropdown :label="$t('uer_label')"
                         v-if="current.agerId && showUer(this.getUserGroupById(current.userGroupId))"
                         v-model="current.uerId"
                         :validator="v$.current.uerId"
                         :options=this.uerListForCurrent
                         option-value="id"
                         option-label="label"
                         class-name="col-6"/>
             <AkDropdown :label="$t('cei_label')"
                         v-if="current.uerId && showCei(this.getUserGroupById(current.userGroupId))"
                         v-model="current.ceiId"
                         :validator="v$.current.ceiId"
                         :options=this.ceiListForCurrent
                         option-value="id"
                         option-label="label"
                         class-name="col-6"/>
           </div>
           <div class="form-row">
             <AkInputText :label="$t('user.emailLogin')"
                          v-if="isLoginRequired"
                          v-model="current.email"
                          :validator="v$.current.email"
                          class-name="col-6"/>
  
            <AkInputText :label="$t('user.password')"
                        v-if="isPasswordRequired"
                         inputType="password"
                         :validator="v$.current.password"
                         v-model="current.password"
                         class-name="col-6"/>
          
          
           </div>
        </div>
      </AkDialog>
      <AkDialog ref="dialogUpdate"
                :title="$t('user.update_dialog')"
                :validate-label="$t('update')"
                width="700px"
                @validate="this.update()">
        <div>
         
           <div class="form-row">
             <AkInputText :label="$t('user.last_name')"
                          v-model="current.lastName"
                          :validator="v$.current.lastName"
                          class-name="col-6"/>
             <AkInputText :label="$t('user.first_name')"
                          v-model="current.firstName"
                          :validator="v$.current.firstName"
                          class-name="col-6"/>
           </div>
           <div class="form-row">

             <AkInputText :label="$t('user.tel')"
                          v-model="current.tel"
                          :validator="v$.current.tel"
                          class-name="col-6"/>
           </div>
           <div class="form-row">
             <AkDropdown :label="$t('user.ug_label')"
                         v-model="current.userGroupId"
                         :validator="v$.current.userGroupId"
                         :options=this.ugList
                         option-value="id"
                         option-label="name"
                         class-name="col-6"/>
             <AkDropdown :label="$t('ager_label')"
                         v-if="current.userGroupId && showAger(this.getUserGroupById(current.userGroupId))"
                         v-model="current.agerId"
                         :validator="v$.current.agerId"
                         :options=this.agerList
                         option-value="id"
                         option-label="label"
                         class-name="col-6"/>
           </div>
           <div class="form-row">
             <AkDropdown :label="$t('uer_label')"
                         v-if="current.agerId && showUer(this.getUserGroupById(current.userGroupId))"
                         v-model="current.uerId"
                         :validator="v$.current.uerId"
                         :options=this.uerListForCurrent
                         option-value="id"
                         option-label="label"
                         class-name="col-6"/>
             <AkDropdown :label="$t('cei_label')"
                         v-if="current.uerId && showCei(this.getUserGroupById(current.userGroupId))"
                         v-model="current.ceiId"
                         :validator="v$.current.ceiId"
                         :options=this.ceiListForCurrent
                         option-value="id"
                         option-label="label"
                         class-name="col-6"/>
           </div>
           <div class="form-row">
            <AkInputText :label="$t('user.emailLogin')"
                          v-if="isLoginRequired"
                          v-model="current.email"
                          :validator="v$.current.email"
                          class-name="col-6"/>
             <AkInputText :label="$t('user.password_edition')"
                          v-if="isPasswordRequired"
                          inputType="password"
                          v-model="current.password"
                          :validator="v$.current.password"
                          class-name="col-6"/>
           </div>
        </div>
      </AkDialog>
      <AkDialog ref="dialogDelete"
                :auto-hide-on-validate="true"
                :cancel-label="$t('no')"
                :title="$t('delete_dialog')"
                :validate-label="$t('yes')"
                width="450px"
                @validate="this.delete()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span >{{ $t('user.confirm_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </AkFormList>
</template>